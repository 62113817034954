import warning from '@amo/core/assets/images/AMO_Icons/Sinistres.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const Sinistres = (props) => {
    const { nextQuestion, data, changeValue, antecedentsSur3ans } = props;

    return (
        <Question
            icon={warning}
            title={getTranslation('antecedents.accidents.title')}
            subtitle={getTranslation('antecedents.subtitle', {count: antecedentsSur3ans ? 3 : 2})}
            position={'bottom'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'sinistre_auto'}
                        value={'1'}
                        label={getTranslation('yes')}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'sinistre_auto'}
                        value={'0'}
                        label={getTranslation('no')}
                        onClick={() => {
                            changeValue('DemandeTarif[Antecedents][ListeSinistres]', []);
                            nextQuestion(300);
                        }}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'sinistre_auto') && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
