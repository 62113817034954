import search from '@amo/core/assets/images/icons/Searching.png';
import { Question } from '@amo/core/components/containers';
import { useEffect } from 'react';
import { SmallSpinner } from '../SmallSpinner';
import {getTranslation} from "../../../../utils/index.js";

export const LargeSpinner = ({ message }) => {
    useEffect(() => {
        window.scroll(0, 0);
    });

    return (
        <div className={'text-center mb-5'}>
            <Question icon={search} title={getTranslation('defaultLoading')} subtitle={message} position={'bottom'} loader>
                <SmallSpinner />
            </Question>
        </div>
    );
};
