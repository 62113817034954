import { Autocomplete, Text } from '@amo/core/components/forms';
import { getCitiesFromFrance } from '@amo/core/utils/requestsApi';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const VilleNaissance = (props) => {
    const { index, data, changeValue, disabled, required } = props;
    const [listVilles, setListVilles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorLoadingCountries, setErrorLoadingCountries] = useState(false);
    const [capbirthIsDown, setCapBirthIsDown] = useState(false);
    const [query, setQuery] = useState('');
    const [queries, setQueries] = useState([]);
    const pays = _.get(data, `DemandeTarif[ListePersonnes][${index}][BirthCountry]`);
    const city = _.get(data, `DemandeTarif[ListePersonnes][${index}][BirthCity]`);

    async function fetchCites(value) {
        let cities = await getCitiesFromFrance({ setLoading, value, setErrorLoadingCountries });

        if (cities.status === 500) {
            setCapBirthIsDown(true)
        } else {
            setCapBirthIsDown(false)
            if (!Array.isArray(cities)) {
                return;
            }
            let list = cities.map((c) => ({
                department: c.sDepartment,
                value: c.sBirthplace,
                label: _.startCase(_.toLower(c.sBirthplace)),
            }));

            const newVilles = _.uniqBy([...listVilles, ...list], 'label');
            setListVilles(newVilles);
        }
    }

    useEffect(() => {
        if (query && query.length >= 2 && pays === 'FRA' && !queries.includes(query)) {
            fetchCites(query);
            setQueries([...queries, query]);
        }
    }, [query]);

    useEffect(() => {
        changeValue(`DemandeTarif[ListePersonnes][${index}][BirthDepartment]`, null)

        if (city && listVilles.length > 0) {
            const villeSelected = _.find(listVilles, ['value',city]);
            if(villeSelected?.department){
                changeValue(`DemandeTarif[ListePersonnes][${index}][BirthDepartment]`, villeSelected.department)
            }
        }
    }, [city]);

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                {!capbirthIsDown && !errorLoadingCountries && pays === 'FRA' ? (
                    <Field
                        component={disabled ? Text : Autocomplete}
                        name={`DemandeTarif[ListePersonnes][${index}][BirthCity]`}
                        placeholder={city || getTranslation('example', {value: "Paris"})}
                        typeFormat={'postal'}
                        data={listVilles}
                        setQuery={setQuery}
                        label={index === 1 ? getTranslation('birthCitySubscriptor') : getTranslation('birthCity')}
                        afterValidate={() => getCitiesFromFrance({ value: city, request: 'ValidateFormBirth' })}
                        disabled={disabled}
                        required={required}
                    />
                ) : (
                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${index}][BirthCity]`}
                        placeholder={getTranslation('example', {value: "Paris"})}
                        typeFormat={'postal'}
                        label={index === 1 ? getTranslation('birthCitySubscriptor') : getTranslation('birthCity')}
                        disabled={disabled}
                        required={required}
                    />
                )}
                {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
            </div>
        </div>
    );
};