import Voiture from '@amo/core/assets/images/AMO_Icons/Voiture.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const AssuranceAuto = (props) => {
    const { nextQuestion, data, antecedentsSur3ans, changeValue } = props;

    return (
        <Question
            icon={Voiture}
            title={getTranslation('antecedents.carInsurance.title')}
            subtitle={getTranslation('antecedents.subtitle', {count: antecedentsSur3ans ? 3 : 2})}
            position={'bottom'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'assure_auto'}
                        value={'1'}
                        label={getTranslation('yes')}
                        onClick={() => {
                            _.get(data, 'firstValeurCrmAuto') &&
                                changeValue(
                                    'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]',
                                    _.get(data, 'firstValeurCrmAuto'),
                                );
                            nextQuestion(300);
                        }}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'assure_auto'}
                        value={'0'}
                        label={getTranslation('no')}
                        onClick={() => {
                            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', '1');
                            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', null);
                            nextQuestion(300);
                        }}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!_.get(data, 'assure_auto') && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
