import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const MalusQuadVerte = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={warning} title={getTranslation('antecedents.malusQuadTrial.title')}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}
                        value={'0'}
                        label={getTranslation("yes")}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}
                        value={'1'}
                        label={getTranslation('no')}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            {_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]') === '0' && (
                <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                    {getTranslation('antecedents.malusQuadTrial.alert')}
                </div>
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    _.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]') !== '1' && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
