import Trajet from '@amo/core/assets/images/AMO_Icons/Trajet.svg';
import info from '@amo/core/assets/images/icons/info.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const UsageVehicule = (props) => {
    const { nextQuestion, data, title } = props;

    return (
        <Question
            icon={Trajet}
            title={title}
        >
            <BulleAide
                icon={info}
                text={getTranslation("vehicle.vehicleUse.help.text")}
                className={
                    data?.typeVehicule === 'moto' ? 'col-lg-12 col-md-12 my-4' : 'col-lg-8 offset-lg-2 col-md-12 my-4'
                }
            />

            <div className={'row justify-content-center '}>
                {data?.typeVehicule === 'moto' && (
                    <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                        <Field
                            component={Radio}
                            type={'radio'}
                            name={'DemandeTarif[Vehicule][UsageVehicule]'}
                            value={'1'}
                            label={getTranslation("vehicle.vehicleUse.walk")}
                            onClick={() => nextQuestion(300)}
                        />
                    </div>
                )}
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][UsageVehicule]'}
                        value={'2'}
                        label={getTranslation("vehicle.vehicleUse.walkAndWork")}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][UsageVehicule]'}
                        value={'3'}
                        label={getTranslation("vehicle.vehicleUse.works")}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Vehicule][UsageVehicule]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation("continue")}
            </button>
        </Question>
    );
};