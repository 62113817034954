import cross from '@amo/core/assets/images/produits/icon_cross.svg';
import moto from '@amo/core/assets/images/produits/icon_moto.svg';
import quad from '@amo/core/assets/images/produits/icon_quad.svg';
import scooter from '@amo/core/assets/images/produits/icon_scooter.svg';
import { SECRET_ENCRYPT } from '@amo/core/constants/constants';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import frTranslation from '../utils/json/translations/fr.json'
import enTranslation from '../utils/json/translations/en.json'

/**
 * retourne le texte du fichier json en fonction de la langue du navigateur
 * ex si html et variable : <p dangerouslySetInnerHTML={{__html: getTranslation("vehicule.welcome", { name: 'APRIL Moto' })}} />
 * @param keyPath
 * @param variables
 * @returns {*|string}
 */
export const getTranslation = (keyPath, variables = {}) => {
    const languageAvailable = {
        fr: frTranslation,
        en: enTranslation
    }

    let selectedLanguage = 'fr' //localStorage.getItem('selectedLanguage');

    if (!selectedLanguage) {
        selectedLanguage = navigator.language.split('-')[0];
    }

    if (!languageAvailable[selectedLanguage]) {
        selectedLanguage = 'fr';
    }

    const getValueFromPath = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    let translatedText = getValueFromPath(languageAvailable[selectedLanguage], keyPath);

    if (!translatedText) {
        return '';
    }

    for (const [key, val] of Object.entries(variables)) {
        translatedText = translatedText.replace(`{${key}}`, val);
    }

    return translatedText;
}
/**
 * ajoute la langue selectionné dans le localstorage
 * @param lang
 */
export const setLanguageInLocalStorage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload()
};

export const crm = [
    { code: '0.50', libelle: getTranslation('profil.crm.crmBonus', {value: '0,50', percent: '50'})},
    { code: '0.51', libelle: getTranslation('profil.crm.crmBonus', {value: '0,51', percent: '49'})},
    { code: '0.52', libelle: getTranslation('profil.crm.crmBonus', {value: '0,52', percent: '48'})},
    { code: '0.53', libelle: getTranslation('profil.crm.crmBonus', {value: '0,53', percent: '47'})},
    { code: '0.54', libelle: getTranslation('profil.crm.crmBonus', {value: '0,54', percent: '46'})},
    { code: '0.55', libelle: getTranslation('profil.crm.crmBonus', {value: '0,55', percent: '45'})},
    { code: '0.56', libelle: getTranslation('profil.crm.crmBonus', {value: '0,56', percent: '44'})},
    { code: '0.57', libelle: getTranslation('profil.crm.crmBonus', {value: '0,57', percent: '43'})},
    { code: '0.58', libelle: getTranslation('profil.crm.crmBonus', {value: '0,58', percent: '42'})},
    { code: '0.59', libelle: getTranslation('profil.crm.crmBonus', {value: '0,59', percent: '41'})},
    { code: '0.60', libelle: getTranslation('profil.crm.crmBonus', {value: '0,60', percent: '40'})},
    { code: '0.61', libelle: getTranslation('profil.crm.crmBonus', {value: '0,61', percent: '39'})},
    { code: '0.62', libelle: getTranslation('profil.crm.crmBonus', {value: '0,62', percent: '38'})},
    { code: '0.63', libelle: getTranslation('profil.crm.crmBonus', {value: '0,63', percent: '37'})},
    { code: '0.64', libelle: getTranslation('profil.crm.crmBonus', {value: '0,64', percent: '36'})},
    { code: '0.65', libelle: getTranslation('profil.crm.crmBonus', {value: '0,65', percent: '35'})},
    { code: '0.66', libelle: getTranslation('profil.crm.crmBonus', {value: '0,66', percent: '34'})},
    { code: '0.67', libelle: getTranslation('profil.crm.crmBonus', {value: '0,67', percent: '33'})},
    { code: '0.68', libelle: getTranslation('profil.crm.crmBonus', {value: '0,68', percent: '32'})},
    { code: '0.69', libelle: getTranslation('profil.crm.crmBonus', {value: '0,69', percent: '31'})},
    { code: '0.70', libelle: getTranslation('profil.crm.crmBonus', {value: '0,70', percent: '30'})},
    { code: '0.71', libelle: getTranslation('profil.crm.crmBonus', {value: '0,71', percent: '29'})},
    { code: '0.72', libelle: getTranslation('profil.crm.crmBonus', {value: '0,72', percent: '28'})},
    { code: '0.73', libelle: getTranslation('profil.crm.crmBonus', {value: '0,73', percent: '27'})},
    { code: '0.74', libelle: getTranslation('profil.crm.crmBonus', {value: '0,74', percent: '26'})},
    { code: '0.75', libelle: getTranslation('profil.crm.crmBonus', {value: '0,75', percent: '25'})},
    { code: '0.76', libelle: getTranslation('profil.crm.crmBonus', {value: '0,76', percent: '24'})},
    { code: '0.77', libelle: getTranslation('profil.crm.crmBonus', {value: '0,77', percent: '23'})},
    { code: '0.78', libelle: getTranslation('profil.crm.crmBonus', {value: '0,78', percent: '22'})},
    { code: '0.79', libelle: getTranslation('profil.crm.crmBonus', {value: '0,79', percent: '21'})},
    { code: '0.80', libelle: getTranslation('profil.crm.crmBonus', {value: '0,80', percent: '20'})},
    { code: '0.81', libelle: getTranslation('profil.crm.crmBonus', {value: '0,81', percent: '19'})},
    { code: '0.82', libelle: getTranslation('profil.crm.crmBonus', {value: '0,82', percent: '18'})},
    { code: '0.83', libelle: getTranslation('profil.crm.crmBonus', {value: '0,83', percent: '17'})},
    { code: '0.84', libelle: getTranslation('profil.crm.crmBonus', {value: '0,84', percent: '16'})},
    { code: '0.85', libelle: getTranslation('profil.crm.crmBonus', {value: '0,85', percent: '15'})},
    { code: '0.86', libelle: getTranslation('profil.crm.crmBonus', {value: '0,86', percent: '14'})},
    { code: '0.87', libelle: getTranslation('profil.crm.crmBonus', {value: '0,87', percent: '13'})},
    { code: '0.88', libelle: getTranslation('profil.crm.crmBonus', {value: '0,88', percent: '12'})},
    { code: '0.89', libelle: getTranslation('profil.crm.crmBonus', {value: '0,89', percent: '11'})},
    { code: '0.90', libelle: getTranslation('profil.crm.crmBonus', {value: '0,90', percent: '10'})},
    { code: '0.91', libelle: getTranslation('profil.crm.crmBonus', {value: '0,91', percent: '9'})},
    { code: '0.92', libelle: getTranslation('profil.crm.crmBonus', {value: '0,92', percent: '8'})},
    { code: '0.93', libelle: getTranslation('profil.crm.crmBonus', {value: '0,93', percent: '7'})},
    { code: '0.94', libelle: getTranslation('profil.crm.crmBonus', {value: '0,94', percent: '6'})},
    { code: '0.95', libelle: getTranslation('profil.crm.crmBonus', {value: '0,95', percent: '5'})},
    { code: '0.96', libelle: getTranslation('profil.crm.crmBonus', {value: '0,96', percent: '4'})},
    { code: '0.97', libelle: getTranslation('profil.crm.crmBonus', {value: '0,97', percent: '3'})},
    { code: '0.98', libelle: getTranslation('profil.crm.crmBonus', {value: '0,98', percent: '2'})},
    { code: '0.99', libelle: getTranslation('profil.crm.crmBonus', {value: '0,99', percent: '1'})},
    { code: '1.00', libelle: getTranslation("profil.crm.noBonusnoMalus") },
    { code: '1.01', libelle: getTranslation('profil.crm.crmMalus', {value: '1,01', percent: '1'})},
    { code: '1.02', libelle: getTranslation('profil.crm.crmMalus', {value: '1,02', percent: '2'})},
    { code: '1.03', libelle: getTranslation('profil.crm.crmMalus', {value: '1,03', percent: '3'})},
    { code: '1.04', libelle: getTranslation('profil.crm.crmMalus', {value: '1,04', percent: '4'})},
    { code: '1.05', libelle: getTranslation('profil.crm.crmMalus', {value: '1,05', percent: '5'})},
    { code: '1.06', libelle: getTranslation('profil.crm.crmMalus', {value: '1,06', percent: '6'})},
    { code: '1.07', libelle: getTranslation('profil.crm.crmMalus', {value: '1,07', percent: '7'})},
    { code: '1.08', libelle: getTranslation('profil.crm.crmMalus', {value: '1,08', percent: '8'})},
    { code: '1.09', libelle: getTranslation('profil.crm.crmMalus', {value: '1,09', percent: '9'})},
    { code: '1.10', libelle: getTranslation('profil.crm.crmMalus', {value: '1,10', percent: '10'})},
    { code: '1.11', libelle: getTranslation('profil.crm.crmMalus', {value: '1,11', percent: '11'})},
    { code: '1.12', libelle: getTranslation('profil.crm.crmMalus', {value: '1,12', percent: '12'})},
    { code: '1.13', libelle: getTranslation('profil.crm.crmMalus', {value: '1,13', percent: '13'})},
    { code: '1.14', libelle: getTranslation('profil.crm.crmMalus', {value: '1,14', percent: '14'})},
    { code: '1.15', libelle: getTranslation('profil.crm.crmMalus', {value: '1,15', percent: '15'})},
    { code: '1.16', libelle: getTranslation('profil.crm.crmMalus', {value: '1,16', percent: '16'})},
    { code: '1.17', libelle: getTranslation('profil.crm.crmMalus', {value: '1,17', percent: '17'})},
    { code: '1.18', libelle: getTranslation('profil.crm.crmMalus', {value: '1,18', percent: '18'})},
    { code: '1.19', libelle: getTranslation('profil.crm.crmMalus', {value: '1,19', percent: '19'})},
    { code: '1.20', libelle: getTranslation('profil.crm.crmMalus', {value: '1,20', percent: '20'})},
    { code: '1.21', libelle: getTranslation('profil.crm.crmMalus', {value: '1,21', percent: '21'})},
    { code: '1.22', libelle: getTranslation('profil.crm.crmMalus', {value: '1,22', percent: '22'})},
    { code: '1.23', libelle: getTranslation('profil.crm.crmMalus', {value: '1,23', percent: '23'})},
    { code: '1.24', libelle: getTranslation('profil.crm.crmMalus', {value: '1,24', percent: '24'})},
    { code: '1.25', libelle: getTranslation('profil.crm.crmMalus', {value: '1,25', percent: '25'})},
    { code: '1.26', libelle: getTranslation('profil.crm.crmMalus', {value: '1,26', percent: '26'})},
    { code: '1.27', libelle: getTranslation('profil.crm.crmMalus', {value: '1,27', percent: '27'})},
    { code: '1.28', libelle: getTranslation('profil.crm.crmMalus', {value: '1,28', percent: '28'})},
    { code: '1.29', libelle: getTranslation('profil.crm.crmMalus', {value: '1,29', percent: '13'})},
    { code: '1.30', libelle: getTranslation('profil.crm.crmMalus', {value: '1,30', percent: '30'})},
    { code: '1.31', libelle: getTranslation('profil.crm.crmMalus', {value: '1,31', percent: '31'})},
    { code: '1.32', libelle: getTranslation('profil.crm.crmMalus', {value: '1,32', percent: '32'})},
    { code: '1.33', libelle: getTranslation('profil.crm.crmMalus', {value: '1,33', percent: '33'})},
    { code: '1.34', libelle: getTranslation('profil.crm.crmMalus', {value: '1,34', percent: '34'})},
    { code: '1.35', libelle: getTranslation('profil.crm.crmMalus', {value: '1,35', percent: '35'})},
    { code: '1.36', libelle: getTranslation('profil.crm.crmMalus', {value: '1,36', percent: '36'})},
    { code: '1.37', libelle: getTranslation('profil.crm.crmMalus', {value: '1,37', percent: '37'})},
    { code: '1.38', libelle: getTranslation('profil.crm.crmMalus', {value: '1,38', percent: '38'})},
    { code: '1.39', libelle: getTranslation('profil.crm.crmMalus', {value: '1,39', percent: '39'})},
    { code: '1.40', libelle: getTranslation('profil.crm.crmMalus', {value: '1,40', percent: '40'})},
    { code: '1.41', libelle: getTranslation('profil.crm.crmMalus', {value: '1,41', percent: '41'})},
    { code: '1.42', libelle: getTranslation('profil.crm.crmMalus', {value: '1,42', percent: '42'})},
    { code: '1.43', libelle: getTranslation('profil.crm.crmMalus', {value: '1,43', percent: '43'})},
    { code: '1.44', libelle: getTranslation('profil.crm.crmMalus', {value: '1,44', percent: '44'})},
    { code: '1.45', libelle: getTranslation('profil.crm.crmMalus', {value: '1,45', percent: '45'})},
    { code: '1.46', libelle: getTranslation('profil.crm.crmMalus', {value: '1,46', percent: '46'})},
    { code: '1.47', libelle: getTranslation('profil.crm.crmMalus', {value: '1,47', percent: '47'})},
    { code: '1.48', libelle: getTranslation('profil.crm.crmMalus', {value: '1,48', percent: '48'})},
    { code: '1.49', libelle: getTranslation('profil.crm.crmMalus', {value: '1,49', percent: '49'})},
    { code: '1.50', libelle: getTranslation('profil.crm.crmMalus', {value: '1,50', percent: '50'})},
    { code: '1.51', libelle: getTranslation('profil.crm.crmMalus', {value: '1,51', percent: '51'})},
    { code: '1.52', libelle: getTranslation('profil.crm.crmMalus', {value: '1,52', percent: '52'})},
    { code: '1.53', libelle: getTranslation('profil.crm.crmMalus', {value: '1,53', percent: '53'})},
    { code: '1.54', libelle: getTranslation('profil.crm.crmMalus', {value: '1,54', percent: '54'})},
    { code: '1.55', libelle: getTranslation('profil.crm.crmMalus', {value: '1,55', percent: '55'})},
    { code: '1.56', libelle: getTranslation('profil.crm.crmMalus', {value: '1,56', percent: '56'})},
];

export const getCRMMotoMin = ({ datePermisA1 = null, datePermisA2 = null, datePermisA = null, datePermisB = null }) => {
    let crms = [getCRM(datePermisA1), getCRM(datePermisA2), getCRM(datePermisA), getCRM(datePermisB)];

    return _.min(crms);
};

export const getCRMAutoMin = (datePermisB) => {
    return getCRM(datePermisB);
};

const getCRM = (date) => {
    if (moment(date, 'DD/MM/YYYY').isValid()) {
        date = moment(date, 'DD/MM/YYYY');
        let now = moment();
        let mois = now.diff(date, 'months');

        if (mois > 154) {
            return 0.5;
        } else if (mois > 142) {
            return 0.51;
        } else if (mois > 130) {
            return 0.54;
        } else if (mois > 118) {
            return 0.57;
        } else if (mois > 106) {
            return 0.6;
        } else if (mois > 94) {
            return 0.64;
        } else if (mois > 82) {
            return 0.68;
        } else if (mois > 70) {
            return 0.72;
        } else if (mois > 58) {
            return 0.76;
        } else if (mois > 46) {
            return 0.8;
        } else if (mois > 34) {
            return 0.85;
        } else if (mois > 22) {
            return 0.9;
        } else if (mois >= 10) {
            return 0.95;
        }
    }

    return 1;
};

export const encrypt = (string) => {
    return CryptoJS.AES.encrypt(JSON.stringify(string), SECRET_ENCRYPT).toString();
};

export const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(JSON.parse(data), SECRET_ENCRYPT);
    const decrypt = bytes.toString(CryptoJS.enc.Utf8);
    return decrypt ? JSON.parse(decrypt) : {};
};

export const getIconByType = (typeVehicule) => {
    const icons = [
        { type: 'moto', icon: moto },
        { type: 'scooter', icon: scooter },
        { type: '50', icon: scooter },
        { type: 'quad', icon: quad },
        { type: 'verte', icon: cross },
    ];

    return _.find(icons, ['type', typeVehicule])?.icon || moto;
};

export const showModal = ({ imageUrl, title, icon, text, html, confirmButtonText = getTranslation('iUnderstand') }) => {
    Swal.fire({
        imageUrl,
        icon,
        title,
        text,
        html,
        confirmButtonText,
    });
};

export const getVehicleHolderName = (listePersonnes, titulaireCarteGrise) => {
    const datenaissanceCond = listePersonnes[0]['DateNaissance'];

    let nomPrenomTitulaire = listePersonnes[0]['Nom'] + ' ' + listePersonnes[0]['Prenom'];
    if (titulaireCarteGrise === '2') {
        nomPrenomTitulaire = listePersonnes[1]['Nom'];
    } else if (
        datenaissanceCond &&
        moment.isMoment(datenaissanceCond) &&
        moment(datenaissanceCond).add(18, 'years') > moment()
    ) {
        nomPrenomTitulaire = listePersonnes[1]['Nom'] + ' ' + listePersonnes[1]['Prenom'];
    }
    return _.trim(nomPrenomTitulaire);
};

export const hameconnage = ({ changeValue, setIndexQuestion }) => {
    /* index à 1 pour sauter la premiere question d'arrivé */
    let index = 1;

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    const search = {};

    for (const [key, value] of params) {
        search[key] = value;
    }

    /* Les mêmes conditions que la question du type de véhicule */
    if (search.vehicule && _.includes(['moto', 'scooter', '50', 'quad', 'verte'], search.vehicule)) {
        index = index + 1;
        switch (search.vehicule) {
            case 'moto':
            case 'scooter':
                changeValue('typeVehicule', search.vehicule);
                changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', '1');
                break;
            case '50':
                changeValue('typeVehicule', '50');
                changeValue('cylindreeVehicule', '50');
                changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', '1');
                break;
            case 'quad':
            case 'verte':
                changeValue('typeVehicule', search.vehicule);
                changeValue('DemandeTarif[Vehicule][UsageVehicule]', '2');
                break;
            default:
                break;
        }
        /* ne change l'index que si on a un véhicule */
        if (search.marque) {
            index = index + 1;
        }
    }

    if (search.marque) {
        changeValue('marqueVehicule', search.marque.toUpperCase());
    }

    index > 1 && setIndexQuestion({ index });
};

/**
 * Récupère la valeur numérique contenue dans une chaîne de caractères
 * @param {string} string Chaîne de caractères
 * @returns {number|null} Valeur numérique ou null si aucune valeur numérique n'a été trouvée
 */
export const getNumberInString = (string) => {
    const regex = /(\d+)/;
    const match = regex.exec(string);
    if (match) {
        return parseInt(match[0]);
    }
    return null;
};

export const getSlugStep = slug => {
    const route = _.find(RouteStep, ['slug', slug]);
    return (route && route !== -1) ? route.step : '';
};
export const getStepSlug = (step, needRDB) => {
    const route = _.find(needRDB ? calculRoutesByRecueilBesoin(needRDB, RouteStep) : RouteStep, ['step', step]);
    return (route && route !== -1) ? route.slug : '';
};
export const getTexteBySource = (source, question) => {
    let newSource = source === 'conseiller' ? 'courtier' : source
    return texteSource[0][question][newSource]
}
export const calculRoutesByRecueilBesoin = (value, routes) => {
    let step = 0
    let cloneRoute = _.cloneDeep(routes)

    if (value === 'non') {
        cloneRoute = _.reject(cloneRoute, ['slug', 'votre-besoin'])
    }

    value !== undefined && _.forEach(cloneRoute, r => {
        r.step = step++
    })

    return cloneRoute
}

export const getInformationVehicule = (vehicule) => {
    const {cylindree, type_vehicule, genre, groupe, type} = vehicule

    if (genre === 'TRI') {
        return getTranslation('function.getInformationVehicule.tricycle');
    } else if (cylindree === 50 && type_vehicule === 'cyclo') {
        return getTranslation('function.getInformationVehicule.cyclo');
    } else if (type_vehicule === 'quad' && cylindree > 50) {
        return getTranslation('function.getInformationVehicule.quad');
    } else if (type.id === 'verte' && cylindree > 50) {
        return getTranslation('function.getInformationVehicule.verte');
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree === 125) {
        return getTranslation('function.getInformationVehicule.cylindree125');
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && genre === 'A2' && parseInt(groupe) < 15) {
        return getTranslation('function.getInformationVehicule.cylindreeSup125GroupeA2');
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && parseInt(groupe) < 15) {
        return getTranslation('function.getInformationVehicule.cylindree125GroupeInf15')
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && groupe === '15') {
        return getTranslation('function.getInformationVehicule.cylindreeSup125Groupe15')
    }  else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && (groupe === '16' || groupe === '17')) {
        return getTranslation('function.getInformationVehicule.cylindreeSup125Groupe16')
    } else if ((type_vehicule === 'moto' || type_vehicule === 'scooter') && cylindree > 125 && groupe === '18') {
        return getTranslation('function.getInformationVehicule.cylindreeSup125Groupe18')
    }

    return '';
};

export const isAnonymized = (str) => {
    if (!str || str.length <= 10) {
        return false;
    }
    const regex = /^.{7}X+.{3}$/;
    return regex.test(str);
}

export const anonymizeString = (str) => {
    if (!str || str.length <= 4) {
        return str;
    }

    const visiblePart = str.slice(0, 4);
    const hiddenPart = 'X'.repeat(str.length - 4);
    return visiblePart + hiddenPart;
};


export const isEqualOrEmpty = (val1, val2) => {
    //On veut que deux champs soient égaux ou tous les deux vides/null
    return val1 === val2 || ((val1 == null || val1 === '') && (val2 == null || val2 === ''));
};


export const customHandleError = (fieldName, remove) => {
    if (document.getElementsByName(fieldName).length > 0) {
        _.forEach(document.getElementsByName(fieldName), (input) => {
            if (input.getAttribute('type') === 'radio') {
                if (remove) {
                    input.labels[0].classList.remove('error');
                } else {
                    input.labels[0].classList.add('error');
                }
            } else {
                if (remove) {
                    input.classList.remove('error');
                } else {
                    input.classList.add('error');
                }
            }
        });
    } else {
        /* Gestion des autocomplete */
        if (remove) {
            document.querySelectorAll(`[data-name='${fieldName}']`)[0]?.classList.remove('error');
        } else {
            document.querySelectorAll(`[data-name='${fieldName}']`)[0]?.classList.add('error');
        }
    }
};
