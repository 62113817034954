import Trophee from '@amo/core/assets/images/AMO_Icons/Trophee.svg';
import { Question } from '@amo/core/components/containers';
import {getTranslation} from "../../../utils/index.js";

export const GoToBesoin = (props) => {
    const { nextQuestion } = props;

    return (
        <Question icon={Trophee} title={getTranslation('antecedents.goToNeed.title')}>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'} dangerouslySetInnerHTML={{__html: getTranslation('antecedents.goToNeed.text')}}/>
                    <p className={'mt-3 f-18'} dangerouslySetInnerHTML={{__html: getTranslation('antecedents.goToNeed.textBis')}}/>
                </div>
            </div>
            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                {getTranslation('antecedents.goToNeed.button')}
            </button>
        </Question>
    );
};
