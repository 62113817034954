import _ from 'lodash';
import moment from 'moment';
import {getTranslation} from "./functions.js";

/**
 * Règles de permis : si status = true la règle est non bloquante et affichera un message d'alerte s'il y a un message
 * @param permis
 * @param vehicule
 * @param dateNaissance
 * @returns {*}
 */
export const validateLicenseByVehicule = ({ permis, vehicule, dateNaissance }) => {
    const { cylindree, type_vehicule, genre } = vehicule;

    const isMoto = type_vehicule === 'moto';
    const isScooter = type_vehicule === 'scooter';
    const isCyclo = type_vehicule === 'cyclo';
    const isQuad = type_vehicule === 'quad' || type_vehicule === 'buggy';
    const isCross = type_vehicule === 'cross';
    const isTrial = type_vehicule === 'trial';
    const isEnduro = type_vehicule === 'enduro';
    const ageConducteur = moment().diff(moment(dateNaissance, 'DD/MM/YYYY'), 'years');
    const hasPermisAM = _.find(permis, ['TypePermis', 'AM']);
    const hasPermisA1 = _.find(permis, ['TypePermis', 'A1']);
    const datePermisA1 = moment(_.find(permis, ['TypePermis', 'A1'])?.DatePermis, 'DD/MM/YYYY');
    const hasPermisA2 = _.find(permis, ['TypePermis', 'A2']);
    const hasPermisA = _.find(permis, ['TypePermis', 'A']);
    const datePermisA = moment(_.find(permis, ['TypePermis', 'A'])?.DatePermis, 'DD/MM/YYYY');
    const hasPermisB = _.find(permis, ['TypePermis', 'B']);
    const datePermisB = moment(_.find(permis, ['TypePermis', 'B'])?.DatePermis, 'DD/MM/YYYY');
    const datePermisBPlus2Ans = moment(datePermisB).add(2, 'years');

    /* Permis Moto et Scooter > 125 cm3 */
    if (
        (isMoto || isScooter || isCross || isTrial || isEnduro) &&
        cylindree > 125 &&
        ageConducteur >= 20 &&
        hasPermisA
    ) {
        return {
            status: true,
        };
    }

    if (cylindree > 125 && ageConducteur >= 18 && genre === 'A2' && (hasPermisA2 || hasPermisA)) {
        return {
            status: true,
        };
    }

    /* Permis Cyclo 50 cm3 */
    if ((isCyclo || isCross || isTrial) && cylindree <= 50 && ageConducteur >= 14) {
        if (moment(dateNaissance, 'DD/MM/YYYY') < moment('1988-01-01')) {
            return { status: true };
        } else {
            if (hasPermisAM || hasPermisA1 || hasPermisA2 || hasPermisA || hasPermisB) {
                return {
                    status: true,
                };
            }
        }
    }

    /* N'affiche pas le message d'erreur tant que la date permis n'est pas renseigné */
    if (hasPermisB && !datePermisB._isValid) {
        return {
            status: true,
        };
    }

    /* Permis Moto et Scooter = 125 cm3 */
    if (
        (isMoto || isScooter || isCross || isTrial || isEnduro) &&
        cylindree > 50 &&
        cylindree <= 125 &&
        ageConducteur >= 16 &&
        (hasPermisA1 ||
            hasPermisA ||
            hasPermisA2 ||
            (hasPermisB && datePermisB < moment('1980-03-01')) ||
            datePermisBPlus2Ans <= moment())
    ) {
        let message;
        if (!(hasPermisA1 || hasPermisA || hasPermisA2) &&
            datePermisBPlus2Ans <= moment() &&
            datePermisB >= moment('1980-03-01')) {
            message = '';
        }
        return {
            status: true,
            message,
        };
    }

    /* N'affiche pas le message d'erreur tant que la date permis n'est pas renseigné */
    if (hasPermisA1 && !datePermisA1._isValid) {
        return {
            status: true,
        };
    }

    /* Permis Tricycle */
    if (
        (isMoto || isScooter || isCross || isTrial || isEnduro) &&
        genre === 'TRI' &&
        ageConducteur >= 21 &&
        (hasPermisA || (hasPermisB && datePermisBPlus2Ans <= moment()))
    ) {
        let message;
        if (datePermisB === datePermisA1 || (hasPermisB && datePermisBPlus2Ans <= moment())) {
            message = '';
        }
        return {
            status: true,
            message,
        };
    }

    /* Permis Quad */
    if (
        isQuad &&
        cylindree === 50 &&
        ageConducteur >= 14 &&
        moment(dateNaissance, 'DD/MM/YYYY') < moment('1988-01-01')
    ) {
        return { status: true };
    } else if (isQuad && cylindree === 50 && ageConducteur >= 14) {
        if (hasPermisA || hasPermisB || hasPermisAM || (hasPermisA1 && datePermisA1 < moment('2013-01-19'))) {
            return {
                status: true,
            };
        }
    }

    /* N'affiche pas le message d'erreur tant que la date permis n'est pas renseigné */
    if (hasPermisA && !datePermisA._isValid) {
        return {
            status: true,
        };
    }

    if (
        isQuad &&
        cylindree >= 50 &&
        ageConducteur >= 16 &&
        ((hasPermisA1 && datePermisA1 < moment('2013-01-19')) ||
            (hasPermisA && datePermisA < moment('2013-01-19')) ||
            hasPermisB)
    ) {
        return {
            status: true,
        };
    }

    if (permis && vehicule && dateNaissance) {
        return {
            status: false,
        };
    }

    return undefined;
};
