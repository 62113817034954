import protection from '@amo/core/assets/images/AMO_Icons/AboutProtection.svg';
import info from '@amo/core/assets/images/icons/info.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const Assure3DerniersMois = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={protection} title={getTranslation("vehicle.ensurelast3Months.title")}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][Assure3DerniersMois]'}
                        value={'1'}
                        label={getTranslation("yes")}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][Assure3DerniersMois]'}
                        value={'0'}
                        label={getTranslation("no")}
                    />
                </div>
            </div>

            <BulleAide
                icon={info}
                title={getTranslation("warning")}
                text={getTranslation("vehicle.ensurelast3Months.help.title")}
            />

            {_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') === '1' && _.get(data, 'DemandeTarif[Vehicule][Assure3DerniersMois]') === '0' && (
                <BulleAide
                    icon={info}
                    title={getTranslation("warning")}
                    text={getTranslation("vehicle.ensurelast3Months.helpBis.title")}
                />
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Vehicule][Assure3DerniersMois]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation("continue")}
            </button>
        </Question>
    );
};