import { Text } from '@amo/core/components/forms';
import { minLentghImmatriculation, validateImmatriculation } from '@amo/core/utils/validateField';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const Immatriculation = ({required, disabled, full}) => {
    return (
        <>
            <div className={'row justify-content-center'}>
                <div className={`${full ? 'col-lg-12 my-2' : 'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}`}>
                    <Field
                        name={`DemandeTarif[Vehicule][Immatriculation]`}
                        label={getTranslation('informationsSubscriptor.numberImmatriculation')}
                        placeholder={getTranslation('example', {value: 'AA 123 BB'})}
                        component={Text}
                        type={'text'}
                        typeFormat={'immat'}
                        validate={[minLentghImmatriculation, validateImmatriculation]}
                        required={required}
                        helpTitle={getTranslation('summary.immatOrMark.help.title')}
                        help={getTranslation('summary.immatOrMark.help.text')}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};