import { DateField, Radio, Select } from '@amo/core/components/forms';
import {
    maxDateToday,
    minDate24Mois,
    minDate36Mois,
    required,
    requiredDateWithError
} from '@amo/core/utils/validateField';
import { Field } from 'redux-form';
import './sinistre.scss';
import classNames from 'classnames';
import {getTranslation} from "../../../utils/index.js";

export const AddSinistre = (props) => {
    const { fields, sinistres, antecedentsSur3ans, className, col } = props;

/*    useEffect(() => {
        fields.length === 0 && fields.push({});
    }, [fields]);*/

    return (
        <div className={classNames('row', className ?? 'justify-content-center')}>
            {fields.map((s, i) => (
                <div key={i} className={`${col ? 'col-md-8 col-12' : 'col-12 col-md-6 col-lg-5'} bloc-sinistre text-start mb-4`} data-name="sinistre">
                    <div className={'bg-secondary-light rounded border'}>
                        <h3 className={'m-0 p-4 ms-4'}>
                            {getTranslation('antecedents.accidentModule.module.title', {count: i+1})}
                            <span className={'btn-close f-14 cursor-pointer'} onClick={() => fields.remove(i)}>Supprimer</span>
                        </h3>
                        <div className={'px-4 pb-4'} style={{ minHeight: 257 }}>
                            <Field component={Select} name={`${s}[NatureSinistre]`} validate={required}>
                                <option value={''}>{getTranslation('antecedents.accidentModule.module.type')}</option>
                                <option value="BDG">{getTranslation('antecedents.accidentModule.module.brokenGlass')}</option>
                                <option value="CIR">{getTranslation('antecedents.accidentModule.module.traffic')}</option>
                                <option value="STA">{getTranslation('antecedents.accidentModule.module.parking')}</option>
                                <option value="VOLVEH">{getTranslation('antecedents.accidentModule.module.theft')}</option>
                                <option value="VAND">{getTranslation('antecedents.accidentModule.module.vandalism')}</option>
                                <option value="INC">{getTranslation('antecedents.accidentModule.module.fire')}</option>
                                <option value="EVTNAT">{getTranslation('antecedents.accidentModule.module.naturalEvent')}</option>
                            </Field>

                            {sinistres &&
                                (sinistres[i]['NatureSinistre'] === 'CIR' ||
                                    sinistres[i]['NatureSinistre'] === 'STA') && (
                                    <div className={'mt-2 mt-md-2'}>
                                        <Field
                                            component={Select}
                                            name={`${s}[ResponsabiliteSinistre]`}
                                            validate={required}
                                        >
                                            <option value={''}>{getTranslation('antecedents.accidentModule.module.responsabilityRate')}</option>
                                            <option value="0">0%</option>
                                            <option value="50">50%</option>
                                            <option value="100">100%</option>
                                        </Field>
                                    </div>
                                )}

                            {sinistres &&
                                (sinistres[i]['NatureSinistre'] === 'CIR' ||
                                    sinistres[i]['NatureSinistre'] === 'STA') && (
                                    <div className={'mt-2 mt-md-2'}>
                                        <Field component={Select} name={`${s}[TypeSinistre]`} validate={required}>
                                            <option value={''}>{getTranslation('antecedents.accidentModule.module.bodyDamages')}</option>
                                            <option value="C">{getTranslation('yes')}</option>
                                            <option value="M">{getTranslation('no')}</option>
                                        </Field>
                                    </div>
                                )}

                            <Field
                                name={`${s}[DateSinistre]`}
                                component={DateField}
                                placeholder={getTranslation('example', {value: '22/03/2020'})}
                                validate={
                                    antecedentsSur3ans ? [requiredDateWithError, maxDateToday, minDate36Mois] : [requiredDateWithError, maxDateToday, minDate24Mois]
                                }
                            />
                            <div className={'row justify-content-center'}>
                                <div className={'col-12 mt-2 f-14'}>
                                    <p>{getTranslation('antecedents.accidentModule.module.vehiclesInvolved')}</p>
                                </div>
                                <div className={'col-lg-6 col-md-6 col-sm-6 col-6 my-2'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={`${s}[VehiculeSinistre]`}
                                        value={'A'}
                                        label={getTranslation('car')}
                                        className={'small'}
                                        validate={required}
                                    />
                                </div>
                                <div className={'col-lg-6 col-md-6 col-sm-6 col-6 my-2'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={`${s}[VehiculeSinistre]`}
                                        value={'M'}
                                        label={getTranslation('moto')}
                                        className={'small'}
                                        validate={required}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className={'col-12 col-md-6 col-lg-5 bloc-sinistre mb-4'} data-name="sinistre">
                <div
                    className={'bg-white rounded bloc-sinistre-add h-100'}
                    onClick={() => fields.push({})}
                    style={{ minHeight: 257 }}
                >
                    <div className={'position-absolute cursor-pointer text-center'}>
                        <span>+</span>
                        <p className={'m-0 mt-3 f-14 fw-600 text-primary-light'}>{getTranslation('antecedents.accidentModule.module.addAccident')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
