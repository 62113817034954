import PropTypes from 'prop-types';
import note from '@amo/core/assets/images/icons/note.png';
import opinions from '@amo/core/assets/images/logos/opinion.svg';

export const Avis = ({ icon, name, content, date, isLast }) => {
    const colClass = isLast ? 'col me-0' : 'col me-4'; // Ajoute une classe conditionnelle

    return (
        <div className={colClass}>
            <div className={'row h-100'}>
                <div className="col bg-white p-4 rounded border">
                    <div className={'d-flex align-items-start text-start justify-content-lg-start'}>
                        <img src={icon} alt={'Icon client'} />
                        <p className={'ms-1 text-dark'}>
                            <span className={'fw-semibold d-inline'}>{name}</span> <i className={'f-12'}>a noté APRIL Moto</i><br />
                            <img src={note} alt={'note'} />
                        </p>
                    </div>
                    <p className={'text-start f-13 mt-2'}>{content}</p>
                    <small className={'text-start d-block mt-4'}>{date} <img src={opinions} className={'mx-2'}/></small>
                </div>
            </div>
        </div>
    );
};

Avis.defaultProps = {
    icon: '',
    name: '',
    content: ''
};

Avis.propTypes = {
    name: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string
};