import { Text } from '@amo/core/components/forms';
import { normalizeNumMarquage } from '@amo/core/utils/normalize.js';
import { Field } from 'redux-form';
import {getTranslation, validateMarquage} from "../../../utils/index.js";

export const NumeroSerie = (props) => {
    return (
        <div className={'row justify-content-center'}>
            <div className={`${props.full ? 'col-lg-12 my-2' : 'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}`}>
                <Field
                    name={`DemandeTarif[Vehicule][VIN]`}
                    label={getTranslation('informationsSubscriptor.numberSerie')}
                    component={Text}
                    type={'text'}
                    typeFormat={'immat'}
                    normalize={normalizeNumMarquage}
                    validate={validateMarquage}
                />
            </div>
        </div>
    );
};
