import { hameconnage } from '@amo/core/utils/functions';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getStepBySlug } from '../../../utils/function';
import FooterContainer from './Footer/FooterContainer';
import HeaderContainer from './Header/HeaderContainer';
import RouteStep from './RouteStep';

const Init = (props) => {
    const {
        step,
        indexQuestion,
        stepInit,
        questionInit,
        goTo,
        nextQuestion,
        data,
        changeValue,
        setIndexQuestion,
        utm_source,
        isAvenant
    } = props;
    const [questions, setQuestions] = useState(document.getElementsByClassName('question'));

    useEffect(() => {
        hameconnage({ changeValue, setIndexQuestion });
    }, []);

    useEffect(() => {
        if (step < 5) {
            const handleBackButton = (e) => {
                e.preventDefault();
                e.returnValue = '';
            };
            window.onpopstate = handleBackButton;
            window.addEventListener('beforeunload', handleBackButton);
            return () => {
                window.onpopstate = null;
                window.removeEventListener('beforeunload', handleBackButton);
            };
        }
    }, [step]);

    useEffect(() => {
        let listQuestion = [];

        /* retire la class fade-in à toutes les questions qui l'ont */
        _.map(document.getElementsByClassName('question'), (q) => {
            if (q.classList.contains('fade-in')) {
                q.classList.remove('fade-in');
            }
            listQuestion.push(q);
        });

        setQuestions(listQuestion);
        /* Ajoute la class fade-in à la question en cours */
        if (document.getElementsByClassName('question')[indexQuestion]) {
            document.getElementsByClassName('question')[indexQuestion].classList.add('fade-in');
        }
    }, [indexQuestion, step, data]);

    /* Fonction pour passer à la question suivante si btn n'est pas disabled et seulement avant l'étape tarif */
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && step < 5) {
            const question = document.getElementsByClassName('question')[indexQuestion];

            const buttons = question.querySelectorAll('button.btn.btn-primary');

            const btn = Array.from(buttons).find(button => button.textContent.trim() === 'Continuer');

            if (btn && !btn.classList.contains('disabled')) {
                nextQuestion();
            }
        }
    };

    /* useEffect qui ajoute et retire l'event listener */
    useEffect(() => {
        if (step < 6) {
            document.querySelector('body').addEventListener('keydown', handleKeyDown);
            return () => {
                document.querySelector('body').removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [indexQuestion]);

    useEffect(() => {
        /* Redirige sur la dernière question où en était l'utilisateur, information prise depuis le localstorage */
        // attention au retour du paiement voir le comportement
        if (stepInit !== undefined && questionInit !== undefined) {
            goTo({ step: stepInit, question: questionInit });
        }
    }, [stepInit, questionInit]);

    // fix en front du champs CRM depuis plus de 3 ans qui arrive à true alors que le client n'a pas 0.50
    useEffect(() => {
        if (_.get(data, "DemandeTarif[ListePersonnes][0][ValeurCrmMoto]") !== "0.50"){
            changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', false)
        }
        if (_.get(data, "DemandeTarif[ListePersonnes][0][ValeurCrmAuto]") !== "0.50"){
            changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', false)
        }
    }, [_.get(data, 'DemandeTarif[Police][MotifAvenant]')]);

    if (!isAvenant) {
        return (
            <>
                <HeaderContainer />
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'text-center col-lg-12 mt-5'}>
                            <p>
                                Malheureusement, nous n'avons pas réussi à vous identifier. <br />
                                <br />
                                Merci de repasser par votre espace pour effectuer l'avenant.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div>
            <HeaderContainer nbQuestions={questions.length} {...props} />
            <div className={step === getStepBySlug('votre-tarif') ? 'container-fluid' : 'container'}>
                <div className={'row'}>
                    <div className={'text-center col-lg-12'}>
                        {data !== undefined &&
                            RouteStep.map((route, index) => {
                                return (
                                    <div key={index}>
                                        {step === route.step &&
                                            route.main({
                                                props,
                                                other: { questions, setQuestions, id: route.slug },
                                            })}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            <FooterContainer step={step} />
        </div>
    );
};

export default Init;
