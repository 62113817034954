import Cancel from '@amo/core/assets/images/AMO_Icons/Cancel.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const AnnulationPermis = (props) => {
    const { nextQuestion, data, changeValue } = props;

    useEffect(() => {
        if (_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') === '0') {
            changeValue('DemandeTarif[Antecedents][DateAnnulationPermis]', '');

            if (_.get(data, 'DemandeTarif[Antecedents][SuspensionPermis]') === '0') {
                changeValue('DemandeTarif[Antecedents][CondamnationAlcoolemie]', '0');
                changeValue('DemandeTarif[Antecedents][CondamnationStupefiants]', '0');
                changeValue('DemandeTarif[Antecedents][CondamnationDelitFuite]', '0');
                changeValue('DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]', '0');
            }
        }
    }, [_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]')]);

    return (
        <Question
            icon={Cancel}
            title={getTranslation('antecedents.cancelLicense.title')}
            subtitle={getTranslation('antecedents.subtitle', {count: 3})}
            position={'bottom'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Antecedents][AnnulationPermis]'}
                        value={'1'}
                        label={getTranslation('yes')}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Antecedents][AnnulationPermis]'}
                        value={'0'}
                        label={getTranslation('no')}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Antecedents][AnnulationPermis]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
