import classNames from 'classnames';
import PropTypes from 'prop-types';
import './radio.scss';

export const Radio = (props) => {
    const {
        icon,
        input,
        type,
        label,
        description,
        onClick,
        meta: { error },
        className,
        disabled,
        dataCy
    } = props;

    return (
        <label
            className={classNames(`button-radio border rounded ${className}`, {
                'border-danger': input.checked && error,
                active: input.checked,
                icon: icon,
                disabled: disabled,
            })}
            data-cy={dataCy}
        >
            <input type={type} {...input} hidden onClick={onClick} disabled={disabled} />
            {icon && (
                <div className={'icon'}>
                    <img src={icon} alt={label} width={40} />
                </div>
            )}
            <div className="d-flex flex-column">
            <span dangerouslySetInnerHTML={{ __html: label }} />
            <span dangerouslySetInnerHTML={{ __html: description }} style={{ fontSize: 12 }} className="text-grey" />
            </div>
        </label>
    );
};

Radio.propTypes = {
    icon: PropTypes.string,
    input: PropTypes.shape({
        checked: PropTypes.bool,
    }),
    type: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    meta: PropTypes.shape({
        error: PropTypes.string,
    }),
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

Radio.defaultProps = {
    input: {
        checked: false,
    },
    type: 'radio',
    label: 'label',
    meta: {
        touched: false,
        error: '',
    },
    className: '',
    disabled: false,
};
