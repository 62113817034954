import './question.scss';
import _ from "lodash";
import info from "../../../assets/images/AMO_Icons/dejaClient.svg";
import {getTranslation} from "../../../utils/index.js";

export const Question = (props) => {
    const {icon, title, subtitle, position, children, loader, full, bandeau, dejaClient } = props

    return (
        <div className={`${!loader && 'question'} rounded text-center ${full ? 'col-lg-10 offset-lg-1' : 'col-lg-8 offset-lg-2'} col-md-12`}>

            {
                bandeau && props.apporteur1.codeBelair === 43397 &&
                (
                    Boolean(dejaClient) && _.get(props.data, `DemandeTarif[ListePersonnes][0][Prenom]`) && _.get(props.data, `DemandeTarif[ListePersonnes][0][Nom]`)
                        ? (
                            <div className={'row rounded-5 border border-primary-light'}>
                                <div className={'d-lg-flex align-items-center gap-4 p-3'}>
                                    <img src={info} alt={'Informations complémentaires'}/>
                                    <div className={'text-start ms-4'}>
                                        <p className={'f-16 fw-bold mb-2'} dangerouslySetInnerHTML={{__html: getTranslation("alreadyCustomer.welcome", {firstName: _.get(props.data, `DemandeTarif[ListePersonnes][0][Prenom]`), lastName: _.get(props.data, `DemandeTarif[ListePersonnes][0][Nom]`)})}} />
                                        <p className={'f-13'}>{getTranslation("alreadyCustomer.thankyou")}</p>
                                    </div>
                                </div>
                            </div>
                        )
                        : (
                            !_.get(props.data, `DemandeTarif[ListePersonnes][0][Nom]`) && !_.get(props.data, `DemandeTarif[ListePersonnes][1][Nom]`) &&
                            <div className={'row rounded-5 border border-primary-light'}>
                                <div className={'d-lg-flex align-items-center gap-4 p-3'}>
                                    <img src={info} alt={'Informations complémentaires'}/>
                                    <div className={'text-start ms-4'}>
                                        <p className={'f-16 fw-bold mb-2'}>{getTranslation("alreadyCustomer.alreadyTitle")}</p>
                                        <p className={'f-13'}>{getTranslation("alreadyCustomer.connexion")}</p>
                                    </div>
                                    <a href={'https://mon-espace.april-moto.com/'} className={'btn btn-primary btn-smaller'}>{getTranslation("alreadyCustomer.cta")}</a>
                                </div>
                            </div>
                        )
                )
            }


            <div className={'p-3'}>
                {icon && <img src={icon} alt={title} className={'mx-2'} width={65} />}
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <div className={`${position === 'top' ? 'flex-column' : 'flex-column-reverse'} d-flex`}>
                            <p className={'question-title mx-2'} data-title={title.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, ' ')} dangerouslySetInnerHTML={{ __html: title }} />
                            <p className={'f-16 text-secondary mx-2 fw-bolder'}>{subtitle}</p>
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

Question.defaultProps = {
    children: <p>Children Question</p>,
};
