import keys from '@amo/core/assets/images/icons/keys.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const UsageNonLivraison = (props) => {
    const { nextQuestion, data, redirectToLivraison, links } = props;

    const handleRedirectToLivraison = () => {
        window.location.href = links['flotte'];
    };
    return (
        <Question
            icon={keys}
            title={getTranslation("vehicle.useNotDelivery.title")}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][UsageNonLivraison]'}
                        value={'0'}
                        label={getTranslation('yes')}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Vehicule][UsageNonLivraison]'}
                        value={'1'}
                        label={getTranslation("no")}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            {_.get(data, 'DemandeTarif[Vehicule][UsageNonLivraison]') === '0' && (
                <>
                    {redirectToLivraison ? (
                        <div className="alert alert-warning mt-3 col-lg-8 offset-lg-2" role="alert">
                            {getTranslation('vehicle.useNotDelivery.alert')}
                        </div>
                    ) : (
                        <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                            Nous sommes désolés, vous ne pouvez pas assurer le véhicule avec cet usage.
                        </div>
                    )}
                </>
            )}

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => redirectToLivraison ? handleRedirectToLivraison() : nextQuestion()}>
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
