import {
    normalizeDate, normalizeDateHour, normalizeHour,
    normalizeIban,
    normalizeImmatriculation,
    normalizeName, normalizeNumber,
    normalizeTel,
    normalizeYear,
} from '@amo/core/utils/normalize';
import PropTypes from 'prop-types';
import './text.scss';
import Swal from "sweetalert2";
import _ from "lodash";

export const Text = (props) => {
    const {
        type = 'text',
        typeFormat,
        label,
        meta: { error },
        required,
        maxLength,
        minLength,
        input: { onChange, name, valueInput, value },
        disabled,
        help,
        helpTitle,
        ...other
    } = props;
    const normalizers = {
        date: normalizeDate,
        dateHour: normalizeDateHour,
        year: normalizeYear,
        immat: normalizeImmatriculation,
        tel: normalizeTel,
        iban: normalizeIban,
        text: normalizeName,
        hour: normalizeHour,
        number: normalizeNumber
    };

    const showPopup = () => {
        Swal.fire({
            icon: 'info',
            html: help,
            title: helpTitle
        });
    }

    const changeData = (e) => {
        const normalize = normalizers[typeFormat];
        let normalizedValue = e.target.value;

        if (normalize) {
            normalizedValue = normalize(normalizedValue);
        }

        if (type === 'number') {
            // Limiter à 2 chiffres après la virgule pour le type 'number'
            const match = normalizedValue.match(/^(\d*\.\d{0,2})|\d*$/);
            normalizedValue = match ? match[0] : '';

            if(other.min && normalizedValue < other.min){
                normalizedValue = other.min;
            } else if(other.max && normalizedValue > other.max){
                normalizedValue = other.max;
            }
        }

        e.target.value = normalizedValue;
        onChange(e);
    };

    const pattern = type === 'date' ? '\\d{1,2}/\\d{1,2}/\\d{4}' : null;

    return (
        <div className="input-text-content">
            <div className={'d-flex justify-content-between'}>
                {label && <label className="text-start label-text mb-1 mx-0">
                        {label}
                        {required && <sup className="text-danger">*</sup>}
                    </label>
                }
                {help && <span className="float-end d-inline-block btn-help" style={{marginTop: '-5px'}} onClick={() => showPopup()}>?</span>}
            </div>
            <input
                name={name}
                type={type}
                required={required}
                value={valueInput || value}
                maxLength={type === 'year' ? '4' : maxLength || ''}
                minLength={minLength || undefined}
                onChange={changeData}
                className={`form-control input-text input-${typeFormat}`}
                pattern={pattern}
                disabled={disabled}
                {...other}
            />
            {((valueInput || String(value)) && error && type !== 'hidden') &&
                <small className="alert alert-danger invalid-feedback d-block error">{error}</small>
            }
        </div>
    );
};

Text.propTypes = {
    type: PropTypes.string.isRequired,
    typeFormat: PropTypes.oneOf(['email', 'date', 'dateHour', 'year', 'immat', 'tel', 'iban', 'text', 'postal']),
    label: PropTypes.string,
    meta: PropTypes.shape({
        error: PropTypes.string,
    }),
    required: PropTypes.bool,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        valueInput: PropTypes.string,
        value: PropTypes.string,
    }),
};

Text.defaultProps = {
    required: false,
    maxLength: '',
};
