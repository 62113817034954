import { Checkbox } from '@amo/core/components/forms';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const ImmatOuMarquage = ({data}) => {
    const date = _.get(data, 'DemandeTarif[Vehicule][DateMEC]');
    const isNotImmat = _.get(data, 'DemandeTarif[Vehicule][VehiculeImmatricule]') === "0";
    const dateMEC = moment(date, 'DD/MM/YYYY') > moment().startOf('day').subtract(30, 'days');

    return (
        <>
            {(dateMEC || (_.includes(['quad', 'verte'], data?.typeVehicule) && isNotImmat)) && (
                <div className={'row justify-content-center'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mb-4 text-start'}>
                            <Field
                                name="immatriculation_check"
                                component={Checkbox}
                                format={(v) => v === '0'}
                                normalize={(v) => (v ? '0' : '1')}
                            >
                                <p className={'mt-1'}>{getTranslation('summary.immatOrMark.infos')}</p>
                            </Field>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};