import _ from 'lodash';
import moment from 'moment';
import {getTranslation} from "./functions.js";

const DATE_FORMAT = 'DD/MM/YYYY';

const dateIsValid = (value, format = DATE_FORMAT) => moment(value, format, true).isValid();
const minAge = (value, age) =>
    moment(value, DATE_FORMAT, true).add(age, 'years') >= moment() ? getTranslation('validateField.minAge', {age}) : undefined;

/**
 * Fonction OnError retourne une erreur si le field doit declancher une erreur s'il est selectionner
 * @param value
 * @returns {*}
 */
export const onError = (value) => (value === 'onError' ? 'error' : undefined);

export const validateDate = value => ( moment(value, 'DD/MM/YYYY') > moment("01/01/1900") ? undefined : getTranslation('validateField, invalidDate'));

/**
 * Return validateEmail function
 * @param value
 * @returns {function}
 */
export const validateEmail = (value) => {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(value)
        ? getTranslation('validateField.invalidEmail')
        : undefined;
};

/**
 * Fonction required pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const required = (value) => (value || value === 0 ? undefined : getTranslation('obligatory'));

/**
 * Fonction required avec affichage d'un message d'erreur pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const requiredWithError = (value) => (value || value === 0 ? undefined : getTranslation('fieldObligatory'));

/**
 * Fonction required avec affichage d'un message d'erreur pour les Fields date de redux-form
 * @param value
 * @returns {String}
 */
export const requiredDateWithError = (value) => {
    if (moment().diff(value, 'years') > 90) {
        return getTranslation('validateField.dateSeemsIncorrect');
    }
    return value && moment.isMoment(value) && dateIsValid(value) ? undefined : getTranslation('fieldObligatory');
};

/**
 * Fonction required pour la date du formulaire de rappel en fonction du champ asap
 * @param value
 * @returns {String}
 */
export const requiredWithoutAsap = (value, allValues) =>
    value || allValues.asap ? undefined : getTranslation('fieldObligatory');

export const minDateValidator = value => {
    if (moment(value, "DD/MM/YYYY").isBefore(moment().subtract(3, 'years'))) {
        return `${getTranslation('validateField.dateCantBefore', {date: moment(moment().subtract(3, 'years')).format('L')})}`;
    }
    return undefined;
};

/**
 * Fonction minDateToday pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const minDateToday = (value) =>
    !value || !moment.isMoment(value) || !dateIsValid(value) || moment(value).startOf('day') >= moment().startOf('day')
        ? undefined
        : getTranslation('validateField.dateIsTooRecent');

/**
 * Fonction minDateHourToday affiche une erreur si la date et heure sont plus tot qu'actuellement
 * @param value
 * @returns {*}
 */
export const minDateHourToday = (value, allValues) => {
    let heureEffetContrat = _.get(allValues, 'HeureEffetContrat');

    if (!value) {
        return undefined;
    }

    if(heureEffetContrat === undefined && !moment.isMoment(value)){
        const [date, time] = value?.trim().split(' ');

        heureEffetContrat = time
    }

    const valueMoment = moment(value, 'DD/MM/YYYY')
        .hour(moment(heureEffetContrat, 'HH:mm').hour())
        .minute(moment(heureEffetContrat, 'HH:mm').minute())


    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !valueMoment.isValid() ||
    valueMoment.isAfter(moment())
        ? undefined
        : getTranslation('validateField.dateTimeIsNoLongerValid');
}


/**
 * Fonction maxDateToday pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDateToday = (value) =>
    moment(value, DATE_FORMAT, true) > moment() ? getTranslation('validateField.dateIsInFutur') : undefined;

/**
 * Fonction minDate36Mois pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const minDate36Mois = (value) =>
    moment(value, DATE_FORMAT, true).add(36, 'months') <= moment()
        ? getTranslation('validateField.dateMustIn3lastMonth')
        : undefined;

/**
 * Fonction minDate24Mois pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const minDate24Mois = (value) =>
    moment(value, DATE_FORMAT, true).add(24, 'months') <= moment()
        ? getTranslation('validateField.dateMustIn2LastYear')
        : undefined;

/**
 * min14Year verifie que la date est supérieur à 14 ans
 * @param value
 * @returns {*}
 */
export const min14Year = (value) => minAge(value, 14);

/**
 * min16Year verifie que la date est supérieur à 14 ans
 * @param value
 * @returns {*}
 */
export const min16Year = (value) => minAge(value, 16);

/**
 * min18Year verifie que la date est supérieur à 18 ans
 * @param value
 * @returns {*}
 */
export const min18Year = (value) => value && minAge(value, 18);

/**
 * Fonction minDateMEC controle si :
 * Moto + Scooter > 80cm3 hors END + TRI + CRO blocage si date inférieure à 01/01/1990
 * @param value
 * @param allValues
 * @returns {string}
 */
export const minDateMEC = (value, allValues) => {
    const produit = _.get(allValues, 'typeVehicule');
    const categorie = allValues.code_categorie;
    const cylindree = _.get(allValues, 'cylindreeVehicule');

    if (
        _.includes(['moto', 'scooter'], produit) &&
        cylindree > 80 &&
        !_.includes(['END', 'TRI', 'CRO'], categorie) &&
        moment(value, DATE_FORMAT, true) < moment('01/01/1990', DATE_FORMAT, true)
    ) {
        return getTranslation('validateField.cantProposeBefore1990');
    }

    return undefined;
};

/**
 * Fonction minDateMEC controle si :
 * Moto + Scooter > 80cm3 hors END + TRI + CRO blocage si date inférieure à 01/01/1990
 * @param value
 * @param allValues
 * @returns {string}
 */
export const errorOnQuad = (value, allValues) => {
    const produit = _.get(allValues, 'typeVehicule');

    if (_.includes(['quad', 'verte'], produit) && value) {
        return getTranslation('validateField.notAvailableForSociety');
    }

    return undefined;
};

/**
 * permisAM vérifie les conditions de blocage pour le Permis AM :
 * Blocage si avant 14 ans
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisAM = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');
    const PermisA1 = _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]');
    const datePermisA1 = _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]');
    const dateCreaA2 = '01/06/2016';

    if (
        value &&
        PermisA1 &&
        datePermisA1 &&
        moment(datePermisA1).isBefore(value) &&
        moment(value).format('L') !== dateCreaA2
    ) {
        return getTranslation('validateField.driverCantLicenseA1BeforeAM');
    }

    return moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(14, 'years')
        ? getTranslation('validateField.cantLicenseAMBefore14')
        : undefined;
};

/**
 * permisA1 vérifie les conditions de blocage pour le Permis A1 :
 * Blocage si avant 16 ans
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisA1 = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');

    return moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(16, 'years')
        ? getTranslation('validateField.cantLicenseA1Before16')
        : undefined;
};

/**
 * permisA2 vérifie les conditions de blocage pour le Permis A2 :
 * Blocage si avant 18 ans
 * Blocage si avant 19/01/2013 et soit pas de permis A soit permis A après 19/01/2013
 *
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisA2 = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');
    const dateCreaA2 = moment('2013-01-19');

    if (moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(18, 'years')) {
        return getTranslation('validateField.cantLicenseA2Before18');
    }

    if (moment(value, 'DD/MM/YYYY', true) < dateCreaA2) {
        return getTranslation('validateField.dateLicenseIsNotValid');
    }
    return undefined;
};

/**
 * permisA vérifie les conditions de blocage pour le Permis A :
 * Blocage si avant 18 ans
 * Blocage si permis A après 19/01/2013 plus une tolérance de 6 mois (19/06/2013) et que la date permis A2 pas remplie
 * Blocage si permis A après 19/01/2013 plus une tolérance de 6 mois (19/06/2013) et que la date permis A2 est inférieure à 2 ans
 * Blocage Si la date du permis A est inférieur à la date du permis A2
 *
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisA = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');
    const dateCreaA2 = moment('2013-06-19');
    const dateObligationPermisA2 = moment('2016-01-01')
    const PermisA2 = _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]');
    const datePermisA2 = moment(
        _.get(allValues, 'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]'),
        DATE_FORMAT,
    );

    if (moment(value, DATE_FORMAT, true) < moment(age, DATE_FORMAT, true).add(18, 'years')) {
        return getTranslation('validateField.cantLicenseABefore18');
    }

    if ((!PermisA2 || !datePermisA2) && moment(value, DATE_FORMAT, true) >= dateCreaA2 && moment(value, DATE_FORMAT, true) <= moment(age, DATE_FORMAT, true).add(24, 'years')){
        return getTranslation('validateField.needDateLicenseA2');
    }

    if ((!PermisA2 || !datePermisA2) && moment(value, DATE_FORMAT, true) >= dateCreaA2 && moment(value, DATE_FORMAT, true) >= dateObligationPermisA2) {
        return getTranslation('validateField.needDateLicenseA2');
    }

    if (moment(value, 'DD/MM/YYYY', true) >= dateCreaA2 && datePermisA2 > moment().subtract(2, 'years')) {
        return getTranslation('validateField.needLicenseA2forLicenseA');
    }

    return undefined;
};

/**
 * permisB vérifie les conditions de blocage pour le Permis B :
 * Blocage si avant 17 ans
 * @param value
 * @param allValues
 * @returns {*}
 */
export const permisB = (value, allValues) => {
    const age = _.get(allValues, 'DemandeTarif[ListePersonnes][0][DateNaissance]');

    return moment(value, 'DD/MM/YYYY', true) < moment(age, DATE_FORMAT, true).add(17, 'years')
        ? getTranslation('validateField.cantLicenseBBefore17')
        : undefined;
};

/**
 * maxValeurVehicule verifie les valeurs du vehicule pour Quad et Moto Verte
 * Blocage Quad si > 26 000
 * Blocage Verte si > 18 000
 * @param value
 * @param allValues
 * @returns {*}
 */
export const maxValeurVehicule = (value, allValues) => {
    const produit = _.get(allValues, 'typeVehicule');
    let valMax = null;

    if (produit === 'quad') {
        valMax = 26000;
    } else if (produit === 'verte') {
        valMax = 18000;
    }

    return valMax && Number(String(value).replace(/\s+/g, '')) > valMax
        ? getTranslation('validateField.pricingVehicleMustLower', {value: valMax})
        : undefined;
};

/**
 * excludeCodePostal exclue certain code postaux
 * Blocage si commence par 97
 * Blocage si commence par 98
 * @param value
 * @param stopByApp1
 * @returns {*}
 */
export const excludeCodePostal = (value) =>
    _.startsWith(value, '98') ? getTranslation('validateField.cantProposeInThisRegion') : undefined;
export const excludeCodePostalWith97 = (value) =>
    _.startsWith(value, '97') || _.startsWith(value, '98')
        ? getTranslation('validateField.cantProposeInThisRegion')
        : undefined;

/**
 * minLentghImmatriculation verifie la longueur de l'immat
 * Blocage si longueur < 5
 * @param value
 * @param allValues
 * @returns {*}
 */
export const minLentghImmatriculation = (value) => {
    return value && value.length < 4 ? getTranslation('validateField.immatMin4') : undefined;
};

export const minLentghTwo = (value) => {
    return value && value.length < 1 ? getTranslation('validateField.cityMin2') : undefined;
};

/**
 * validateImmatriculation Valide la plaque immatriculation
 * @param value
 * @param allValues
 * @returns {*}
 */
export const validateImmatriculation = (value) => {
    if (!value) {
        return value;
    }

    const filteredValue = value.replace(/[!@#$%^&*\-(),.?":{}|<> \t\r\n]/g, '');

    // Nouvelle plaque immatriculation : AA123AA ou AA123A ou C254L
    if (/^[a-zA-Z]{1,2} ?-?[0-9]{2,3} ?-?[a-zA-Z]{1,2}$/.test(filteredValue)) {
        return undefined;
    }

    // Ancienne plaque immatriculation : 1234AA12
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9]{2}$/.test(filteredValue)) {
        return undefined;
    }

    // Ancienne plaque corse : 1234AAA1A
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9][a-bA-B]$/.test(filteredValue)) {
        return undefined;
    }

    // Format 123AA12 ou 123AAA12
    if (/^[0-9]{3}[a-zA-Z]{2,3}[0-9]{2}$/.test(filteredValue)) {
        return undefined;
    }

    // Format A12A
    if (/^[a-zA-Z][0-9]{2}[a-zA-Z]$/.test(filteredValue)) {
        return undefined;
    }

    return getTranslation('validateField.immatInvalid');
};

/**
 * validatePhone verifie la longueur du numero de tel et vérifie qu'il commence par 0
 * Blocage si longueur < 10 ou commence par autre chose que 0
 * @param value
 * @returns {*}
 */
export const validatePhone = (value) => {
    if (value && !_.startsWith(value, '0')) {
        return getTranslation('validateField.phoneMustStart0');
    }

    return value && _.replace(value, /\s/g, '').length < 10
        ? getTranslation('validateField.phoneMust10')
        : undefined;
};

/**
 * Fonction maxDate30Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const isBeforeAv = (value, allValues) => {
    const selectedDateMoment = moment(value, 'DD/MM/YYYY');
    const derPiece = moment(_.get(allValues, 'DemandeTarif[Police][DateEffetContrat]'), 'DD/MM/YYYY')
    if (selectedDateMoment.isBefore(derPiece, 'day')) {
        return getTranslation('validateField.dateCantLowerContract');
    } else {
        return undefined
    }

}

/**
 * Fonction maxDate30Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDate30Days = value => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm')

    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !moment(valueMoment).isValid() ||
    moment(valueMoment) <= moment().add(30, 'day').set('hour', 23).set('minute', 59).set('second', 59)
        ? undefined
        : getTranslation('validateField.dateIsAfter30Days');
}

/**
 * Fonction maxDate60Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDate60Days = (value) => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm');

    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !dateIsValid(valueMoment) ||
    moment(valueMoment) <= moment().add(60, 'day').set('hour', 23).set('minute', 59).set('second', 59)
        ? undefined
        : getTranslation('validateField.dateIsAfter60Days');
};


/**
 * Fonction minDate6Months affiche une erreur si la date et heure sont plus de 6 mois antérieur
 * @param value
 * @returns {*}
 */
export const minDate6Months = (value) => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm');

    return !valueMoment ||
    !moment.isMoment(valueMoment) ||
    !dateIsValid(valueMoment) ||
    moment(valueMoment).isAfter(moment().subtract(6, 'month'))
        ? undefined
        : getTranslation('validateField.dateIsAfter6Month');
};

/**
 * Fonction maxDate33Days pour les Fields de redux-form
 * @param value
 * @returns {String}
 */
export const maxDate33Days = (value) => {
    const valueMoment = moment(value, 'DD/MM/YYYY HH:mm');

    return !valueMoment ||
        !moment.isMoment(valueMoment) ||
        !dateIsValid(valueMoment) ||
        moment(valueMoment) <= moment().add(33, 'day').set('hour', 23).set('minute', 59).set('second', 59)
        ? undefined
        : getTranslation('validateField.dateSelectedIsNotValid');
};

/**
 * minLengthBIC verifie la longueur du code BIC
 * Blocage si longueur < 8
 * @param value
 * @returns {*}
 */
export const minLengthBIC = (value) => {
    return value && value.length !== 8 ? getTranslation('validateField.bicMust8') : undefined;
};

/**
 * validateIban verifie que les 3 derniers caractère ne comporte pas de lettres
 * Blocage si 3 derniers caractère sont des lettres
 * @param value
 * @returns {*}
 */
export const validateIban = (value) => {
    const lastChar = value.slice(-3);
    return value && !/^\d+$/.test(lastChar) ? getTranslation('validateField.ibanNotValid') : undefined;
};

/**
 * valeurVehicule verifie que la valeur du véhicule est entre 800 et 1000
 * @param value
 * @returns {*}
 */
export const valeurVehicule = value => (value < 800 || value > 6000) ? getTranslation('validateField.pricingVehicle') : undefined

/**
 * min18Year verifie que la date est inférieur à 6 mois
 * @param value
 * @returns {*}
 */
export const dateMin6Month = value => moment(value) <= moment().subtract(6, 'month') ? getTranslation('validateField.onlyBikeProLess6Month') : undefined

/**
 * Return validateEmailDifferente function
 * @param value
 * @returns {function}
 */
export const validateEmailDifferente = (value, allValues) => {
    return _.get(allValues, 'DemandeTarif[ListePersonnes][0][Email]') === _.get(allValues, 'DemandeDevis[EmailCourtier]')
        ? getTranslation('validateField.emailAlreadyUse') : undefined
}

/**
 * minLentghMarquage verifie la longueur du numero de marquage
 * Blocage si longueur < 12
 * @param value
 * @param allValues
 * @returns {*}
 */
export const minLentghMarquage = value => {
    return value && (value.length <3 || value.length > 15)? getTranslation('validateField.markMust3Or15') : undefined
}


export const validateVIN = (value) => {
    return value && value.length !== 7 ? getTranslation('validateField.vinMust7') : undefined;
}

export const validateMarquage = (value) => {
    return value && value.length < 9 ? 'Doit être > 9 caractères' : undefined;
}

export const isValid = value => !value || !moment.isMoment(value) ? undefined : !moment(value).isValid() && value?._i.length === 10 ? getTranslation('validateField.invalidDate') : undefined;