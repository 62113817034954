import axios from 'axios';
import { API_URL_WHEELS, SERVICES_URL } from '../constants/index.js';

const defaultOptions = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

export const requestWheels = axios.create({
    baseURL: API_URL_WHEELS,
    ...defaultOptions,
});

export const requestServices = axios.create({
    baseURL: SERVICES_URL,
    ...defaultOptions,
});