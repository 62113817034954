import { Radio } from '@amo/core/components/forms';
import { getTranslation } from '@amo/core/utils/functions';
import { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { normalizeEuro } from '@amo/core/utils/normalize';
import './bloc-option.scss';

export const BlocOption = (props) => {
    const { icon, title, changeValue, fractionnement, name, data, selected, text, codePromo, goodForMe, from } = props;

    const [toggleSwitch, setToggleSwitch] = useState(
        selected !== undefined && data.some(option => option.value === selected)
    );

    const [tempSelect, setTempSelect] = useState(
        selected !== undefined ? selected : data[0]?.value || ''
    );

    useEffect(() => {
        setToggleSwitch(selected !== undefined && data.some(option => option.value === selected));
    }, [selected, data]);

    const handleToggle = () => {
        setToggleSwitch(!toggleSwitch);

        if (!toggleSwitch) {
            // Si le switch est activé, on sélectionne la première option
            const firstOption = data[0]?.value || '';
            setTempSelect(firstOption);
            changeValue(name, firstOption);
        } else {
            // Si le switch est désactivé, on vide la sélection
            setTempSelect('');
            changeValue(name, '');
        }
    };

    const buildLabelOption = (o) => {
        if (
            codePromo?.Eligible &&
            (codePromo?.TypePromo === 'ReductionPourcent' || o.dataAffichage.prix > o.dataAffichage.prix_promo)
        ) {
            return `<span>${o.label} pour <b class='text-decoration-line-through text-grey'>${normalizeEuro(
                o.dataAffichage.prix,
            )}</b> <b class='text-secondary'>${normalizeEuro(
                o.dataAffichage.prix_promo,
            )}</b>/${fractionnementChoisi}</span>`;
        } else {
            return `${o.label} ${getTranslation('for')} <b>${normalizeEuro(o.dataAffichage.prix)}</b>/${fractionnementChoisi}`;
        }
    };

    const fractionnementChoisi = fractionnement === 'M' ? getTranslation('months') : getTranslation('year');

    return (
        <div className={'col-lg-12 col-12 mb-4 bloc-option'}>
            <div
                style={{ height: '100%' }}
                className={`position-relative content-bloc-option mb-3 px-3 pb-3 pt-2`}
            >
                <div
                    className={'d-flex flex-column justify-content-between align-items-end'}
                    style={{ height: '100%' }}
                >
                    <div className={'col-12'}>
                        <div className="row align-items-start mb-3">
                            <div className="col-xl-1 col-lg-2 col-md-1 col-sm-2 col-3 text-start d-none d-sm-block">
                                <img src={icon} alt={title} width={70} />
                            </div>
                            <div className="col-12 col-sm-10">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={icon}
                                            alt={title}
                                            width={40}
                                            className="me-2 d-block d-sm-none" // Visible uniquement en version mobile
                                        />
                                        <p className={'mt-xl-4 mb-xl-2 f-20 text-primary fw-bold text-start'}>{title}</p>
                                    </div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={toggleSwitch}
                                            onChange={handleToggle}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <small
                                    className={'float-start text-start f-14 mb-3 mt-2'}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                />
                                {data.length > 1 ? (
                                    <>
                                        {data.map((option, index) => (
                                            <Field
                                                className={`small option small-2 f-14`}
                                                key={index}
                                                component={Radio}
                                                type={'radio'}
                                                name={name}
                                                value={option.value}
                                                label={buildLabelOption(option)}
                                                onClick={(e) => setTempSelect(e.target.value)}
                                                disabled={!toggleSwitch} // Désactiver les options si le switch est désactivé
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <Field
                                        className={`small option`}
                                        component={Radio}
                                        type={'radio'}
                                        name={name}
                                        value={data[0].value}
                                        label={buildLabelOption(data[0])}
                                        onClick={(e) => setTempSelect(e.target.value)}
                                        disabled={!toggleSwitch} // Désactiver l'option si le switch est désactivé
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

BlocOption.defaultProps = {
    goodForMe: true,
    from: true,
};
