import multi from '@amo/core/assets/images/AMO_Icons/multi-detention.svg';
import PropTypes from 'prop-types';
import _ from "lodash";
import './multidetention.scss';
import Swal from "sweetalert2";
import InformationsGris from "../../../assets/images/icons/informations-gris.png";

export const Multidetention = (props) => {
    const { data, courtier } = props;

    const showModal = () => {
        Swal.fire({
            icon: 'info',
            title: 'Avantage Multi-contrats APRIL Moto',
            html: `En assurant une nouvelle moto pour un même souscripteur, ${courtier ? "votre client bénéficiera" : "vous bénéficierez"} d’une réduction de cotisation permanente jusqu’à -20% (hors options, hors frais) sur ${courtier ? "son" : "votre"} contrat moto le plus ancien.<br/>La réduction s’appliquera automatiquement dès la validation de ${courtier ? "son" : "votre"} nouveau contrat moto, sans aucune démarche de ${courtier ? "sa" : "votre"} part.`,
            showConfirmButton: true,
            confirmButtonText: 'J\'ai compris',
            showCloseButton: true,
        });
    }
    
    return (
        <div className={'row rounded-4 bg-success border border-success'}>
            <div className={'d-lg-flex align-items-center gap-4 p-3 flex-column flex-lg-row'}>
                <div className={'d-flex align-items-center d-lg-none w-100'}>
                    <img src={multi} alt={'Informations complémentaires'} className={'me-3'}/>
                    <p className={'f-16 fw-bold mb-2 text-start'}>Avantage Multi-contrats APRIL Moto</p>
                </div>
                <div className={'d-none d-lg-block'}>
                    <img src={multi} alt={'Informations complémentaires'} className={'ms-3'}/>
                </div>
                <div className={'text-start'}>
                    <p className={'f-16 fw-bold mb-2 d-none d-lg-block'}>Avantage Multi-contrats APRIL
                        Moto</p>
                    <p className={'f-13'}>Assurez une nouvelle moto et débloquez automatiquement jusqu'à
                        -20% sur {courtier ? "sur le 1er contrat de votre client" : "votre 1er contrat"}.</p>
                    <p className={'f-12 text-grey'} onClick={() => showModal()}>
                        <img src={InformationsGris} alt="?" className={'align-middle'}/>{' '}
                        <u className={'pb-1 cursor-pointer'}>En savoir plus</u>
                    </p>
                </div>
                <button className={'no-cursor btn btn-success btn-smaller ms-auto mt-3 mt-lg-0'}>Avantage
                    multi-contrats débloqué&nbsp;!
                </button>
            </div>
        </div>
    );
};

Multidetention.defaultProps = {
    title: '',
    text: '',
    icon: null,
};

Multidetention.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
};