import Calendar from '@amo/core/assets/images/AMO_Icons/Calendar.svg';
import { Question } from '@amo/core/components/containers';
import { DateField } from '@amo/core/components/forms';
import { maxDateToday, min14Year, min16Year, min18Year } from '@amo/core/utils/validateField.js';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const DateNaissance = (props) => {
    const { nextQuestion, data, changeValue } = props;

    const souscripteurIsMineur = (data) => {
        return (
            moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') >=
            moment()
        );
    };

    useEffect(() => {
        if (moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY', true).isValid()) {
            changeValue(
                'DemandeTarif[ListePersonnes][0][ConducteurMineur]',
                souscripteurIsMineur(data) ? 'true' : 'false',
            );
        }
    }, [_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]')]);

    const isNotValid = () => {
        let date = _.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]');

        return (
            maxDateToday(date) ||
            (Number(data?.cylindreeVehicule) <= 50
                ? min14Year(date)
                : Number(data?.cylindreeVehicule) <= 125
                ? min16Year(date)
                : min18Year(date)) ||
            !moment(date, 'DD/MM/YYYY', true).isValid()
        );
    };

    return (
        <Question icon={Calendar} title={getTranslation('profil.birthDate.title')}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'} data-name={'datenaissanceconducteur'}>
                    <Field
                        name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}
                        component={DateField}
                        placeholder={getTranslation("example", {value: '22/03/1990'})}
                        validate={[
                            maxDateToday,
                            Number(data?.cylindreeVehicule) <= 50
                                ? min14Year
                                : Number(data?.cylindreeVehicule) <= 125
                                ? min16Year
                                : min18Year,
                        ]}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                {getTranslation("continue")}
            </button>
        </Question>
    );
};
