import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const ResiliationAncienAssureur = (props) => {
    const { nextQuestion, data, changeValue } = props;

    return (
        <Question
            icon={warning}
            title={getTranslation('antecedents.terminateOldInsurer.title')}
            subtitle={getTranslation('antecedents.subtitle', {count: 3})}
            position={'bottom'}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                        value={'1'}
                        label={getTranslation('yes')}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                        value={'0'}
                        label={getTranslation('no')}
                        onClick={() => {
                            changeValue('DemandeTarif[Antecedents][MotifResiliationAssureur]', '');
                            changeValue('DemandeTarif[Antecedents][DateResiliationAssureur]', '');
                            nextQuestion(300);
                        }}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[Antecedents][ResiliationAssureur]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
