import Protection from '@amo/core/assets/images/AMO_Icons/Protection.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const DommagesTousAccidents = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question
            icon={Protection}
            title={getTranslation('yourNeedes.damagesAllAccidents.title')}
            subtitle={getTranslation('yourNeedes.damagesAllAccidents.subtitle')}
            position={'bottom'}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[DevoirConseil][DevoirDommage]'}
                        value={'1'}
                        label={getTranslation('yes')}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[DevoirConseil][DevoirDommage]'}
                        value={'0'}
                        label={getTranslation('no')}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[DevoirConseil][DevoirDommage]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
