import { Radio } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const Civilite = (props) => {
    const { index, disabled } = props;

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={`DemandeTarif[ListePersonnes][${index}][Civilite]`}
                    value={'1'}
                    label={getTranslation('mrs')}
                    className={'small'}
                    disabled={disabled}
                />
            </div>
            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={`DemandeTarif[ListePersonnes][${index}][Civilite]`}
                    value={'0'}
                    label={getTranslation('mr')}
                    className={'small'}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};