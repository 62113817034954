import {getTranslation} from './functions.js'

export const listFormeJuridique = [
    { id: 'EARL', name: 'EARL' },
    { id: 'CO', name: 'Commerçant' },
    { id: 'EI', name: 'EI' },
    { id: 'EURL', name: 'EURL' },
    { id: 'GIE', name: 'GIE' },
    { id: 'SA', name: 'SA' },
    { id: 'SARL', name: 'SARL' },
    { id: 'SAS', name: 'SAS' },
    { id: 'SASU', name: 'SASU' },
    { id: 'SC', name: 'SC' },
    { id: 'SCA', name: 'SCA' },
    { id: 'SCI', name: 'SCI' },
    { id: 'SCM', name: 'SCM' },
    { id: 'SCOP', name: 'SCOP' },
    { id: 'SCP', name: 'SCP' },
    { id: 'SCS', name: 'SCS' },
    { id: 'SELRL', name: 'SELRL' },
    { id: 'SNC', name: 'SNC' },
    { id: 'SAEM', name: 'SAEM' },
    { id: 'ETS', name: 'ETS' },
    { id: 'EIRL', name: 'EIRL' },
];

export const nbMonthList = (maxMonths) => {
    const monthList = [];
    for (let i = 1; i < maxMonths; i++) {
        monthList.push({
            id: i,
            name: `${i} ${getTranslation('months')}`,
        });
    }
    return monthList.reverse();
};
