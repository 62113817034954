import warning from '@amo/core/assets/images/icons/warning.svg';
import { Question } from '@amo/core/components/containers';
import { DateField, Select } from '@amo/core/components/forms';
import { maxDateToday, minDate36Mois } from '@amo/core/utils/validateField.js';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import {getTranslation} from "../../../utils/index.js";

export const InformationsResiliationAncienAssureur = (props) => {
    const { nextQuestion, data } = props;

    const isNotValid = () => {
        const date = _.get(data, 'DemandeTarif[Antecedents][DateResiliationAssureur]');

        return (
            maxDateToday(date) ||
            !moment(date, 'DD/MM/YYYY', true).isValid() ||
            !_.get(data, 'DemandeTarif[Antecedents][MotifResiliationAssureur]')
        );
    };

    return (
        <Question icon={warning} title={getTranslation('antecedents.informationTerminateOldInsurer.title')}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                        component={Select}
                        label={getTranslation("antecedents.informationTerminateOldInsurer.select.title")}
                    >
                        <option value={''}>{getTranslation("antecedents.informationTerminateOldInsurer.select.title")}</option>
                        <option value={'NP'}>{getTranslation('antecedents.informationTerminateOldInsurer.select.nonPayment')}</option>
                        <option value={'FS'}>{getTranslation('antecedents.informationTerminateOldInsurer.select.frequencyAccident')}</option>
                        <option value={'FD'}>{getTranslation('antecedents.informationTerminateOldInsurer.select.falseDeclaration')}</option>
                        <option value={'A'}>{getTranslation('antecedents.informationTerminateOldInsurer.select.others')}</option>
                    </Field>
                </div>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-6 col-sm-6 col-12 my-2'}>
                        <Field
                            name={`DemandeTarif[Antecedents][DateResiliationAssureur]`}
                            label={getTranslation('antecedents.informationTerminateOldInsurer.whichDate')}
                            component={DateField}
                            placeholder={getTranslation('example', {value: '22/03/2020'})}
                            validate={[maxDateToday, minDate36Mois]}
                        />
                    </div>
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};
